import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Andrew Lucentini | Software Engineer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Explore the wild wonders of Andrew.', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hello! my name is',
  name: 'Andrew.',
  subtitle: 'I\'m a software engineer with a passion for learning.',
  cta: 'Resume',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: '',
  paragraphTwo: '',
  paragraphThree: '',
  resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'materials.png',
    title: 'Materials Lab Simulator',
    info: 'An educational simulation tool written in Python 3 designed for a first year engineering course at McMaster University.',
    info2: 'Designed for scalability using the PAC architecture.',
    url: 'https://github.com/lucenta/MaterialsLabSimulator',
    extraLinkName: 'See Published Paper',
    extraLink: 'https://strategy.asee.org/enhancing-student-experiential-learning-opportunities-in-materials-science-through-the-development-of-online-virtual-laboratories', 
  },
  {
    id: nanoid(),
    img: 'P2PFree.png',
    title: 'P2P Free',
    info: 'A command line file synchronization program written in Python 3.',
    info2: 'The program can synchronize files from any number of users.',
    url: 'https://github.com/lucenta/P2PFree',
    extraLinkName: '',
    extraLink: '', 
  },
  {
    id: nanoid(),
    img: 'BlockBuilder.png',
    title: 'BlockBuilder',
    info: 'BlockBuilder is an improved, modularized version of Fogleman\'s Python/Pyglet Minecraft.',
    info2: 'The game also uses TCP sockets to allow users to host and join worlds over the network.',
    url: 'https://github.com/lucenta/BlockBuilder',
    extraLinkName: '',
    extraLink: '', 
  },
  {
    id: nanoid(),
    img: 'filtered.jpg',
    title: 'ImageFade',
    info: 'A multi-threaded command line image filtering program written in C.',
    info2: '',
    url: 'https://github.com/lucenta/ImageFade',
    extraLinkName: '',
    extraLink: '', 
  },
  {
    id: nanoid(),
    img: 'adidas.png',
    title: 'Adidas Ad Music Score',
    info: 'One of my biggest hobbies aside from coding is making music. I had the opportunity to score the music for an Adidas football ad.',
    info2: '',
    url: '',
    extraLinkName: 'Watch on Youtube',
    extraLink: 'https://www.youtube.com/watch?v=ApeXTN8eWqU', 
  }
];

// CONTACT DATA
export const contactData = {
  cta: 'Want to chat?',
  btn: 'Email',
  email: 'lucentiniandrew@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'github',
      url: 'https://www.github.com/lucenta',
    },
  ]
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
